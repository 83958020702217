.ql-container {
  height: 160px !important;
  font-size: 16px !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ql-editor ol,
.ql-editor ul {
  padding-left: 0.5rem !important;
}

.ql-tooltip {
  left: 10px !important;
}

.word-counter-container {
  display: flex;
  justify-content: flex-end;
  padding: 4px 10px;
  color: rgb(108, 108, 108);
}

.counter-editor {
  box-sizing: border-box !important;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.counter-editor-dark {
  box-sizing: border-box !important;
  background-color: #f1f1f1;
  border-radius: 4px;
}

.ql-mention-list-container {
  overflow-y: auto;
  max-height: 220px;
}
