.side-bar-container{
  height: 100%;
  background-color: #fafafa;
}
.side-bar-with-button {
  background-color: #fafafa;
  padding-bottom: 1px;
}

.side-bar-with-button.settings {
  margin-left: 1rem;
}

.hr-name {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #ff6c54;
  font-style: normal;
  text-align: left;
}

.dashboard-name {
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.5rem;
  color: #52bbab;
}

.org-name {
  position: relative;
  font-weight: 400;
  font-size: 0.8rem;
  margin-left: 0.5rem;
  bottom: 1.5px;
}

.link {
  overflow: hidden;
  text-decoration: none;
}

.side-bar-link-button {
  text-decoration: none;
  color: #1d1e1f;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

.side-bar-text {
  margin-left: 0.5rem;
  margin-bottom: 5px;
}

.side-bar-icon {
  margin-left: 2rem;
}

.side-bar-icon__size {
  font-size: 1.3rem !important;
}

.side-bar-buttons {
  margin-top: 2rem;
  background-color: #fafafa;
}

.side-bar-buttons-items {
  background-color: #fafafa;
}

.side-bar-button {
  text-align: center;
  display: flex;
  margin: 4px 0px;
  height: 3.5rem;
  align-items: center;
}

.side-bar-button:hover {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
}

.side-bar-button.-active {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
}

.side-bar-active-area {
  margin: 0 2.5rem;
}

.setting-title-container:hover {
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  border-radius: 1rem;
}

.setting-title-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fafafa;
  margin: 0 40px;
  height: 3.5rem;
  cursor: pointer;
}

.setting-title {
  display: flex;
  align-items: center;
  margin-left: 30px;
}

.settings-side-bar-text {
  margin-left: 5px;
}

.side-bar-expand-icon {
  margin-right: 30px;
  margin-top: 6px;
}
