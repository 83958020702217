.error-container {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 6.25rem 3.125rem 2.063rem 3.125rem;
  height: 100%;
  align-items: center;
}

.error {
  color: #73797e;
  line-height: 41px;
  font-size: 30px;
  word-break: break-word;
}

.alert-error-message {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.appid-link{
  text-decoration: none;
  color:#0000EE;
  font-weight: 500;
}
