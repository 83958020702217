.icon {
  min-width: 20px !important;
  margin-right: 10px;
  margin-left: 5px;
}

.profile-user {
  border: none;
  background-color: #1d1e1f;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
  text-align: center;
  line-height: 30px;
  margin-right: 10px;
}

.profile-profile {
  display: flex;
  padding: 10px 16px;
  align-items: center;
  margin-bottom: 8px;
}

.profile-name {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 2px 0;
}

.profile-email {
  font-size: 11px;
  font-weight: 500;
  margin: 0;
}

.link {
  text-decoration: none;
  color: black;
}
