.application_form_over_lay {
  margin-left: 25%;
}
.application-same-row {
  display: flex;
  flex-direction: column;
}
.position-agencies-info {
  display: flex;
  flex-direction: column;
}

::-webkit-calendar-picker-indicator {
  margin-left: -15px;
}

.MuiRadio-colorSecondary:hover {
  background-color: rgba(82, 187, 171, 0.1) !important;
}

.complete-form-applicant {
  width: 100%;
  background-color: #fafafa;
}

.main-page-applicant {
  width: 50%;
  min-width: 800px;
  box-shadow: 0rem 0.125rem 0.625rem rgba(0, 0, 0, 0.15);
}

.page-applicant {
  align-items: center;
  padding: 3.125rem;
  background-color: #ffffff;
}

.application-same-row-wrapper {
  display: flex;
  column-gap: 5px;
}

.application-same-row-wrapper-item {
  display: flex;
  column-gap: 5px;
  align-items: center;
  margin-top: 5px;
}

.applicant-link-arrow {
  color: #52bbab;
  background-color: #eff0f1;
  padding: 0.375rem 0.125rem 0.438rem 0.875rem;
  border-radius: 0.5rem;
}

.MuiTextField-root label {
  top: -0.5rem;
}

.MuiTextField-root label[data-shrink='true'] {
  top: 0rem;
}

.head-applicant {
  background-color: #fff;
  border: 1px solid #dadee0;
  padding: 15px 30px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 1.563rem;
}

.head-text-applicant {
  margin-left: 1rem;
  font-size: 1.875rem;
  font-weight: 600;
  color: #1d1e1f;
}

.applicant-container-box {
  width: 100%;
  padding-top: 0.625rem;
  background-color: #fafafa;
  border-radius: 0.5rem;
  padding-bottom: 0.625rem;
}

.applicant-info {
  padding: 1.875rem;
  background-color: #ffffff;
  margin-left: 0.625rem;
  margin-right: 0.625rem;
  border-radius: 0.5rem;
}

.inputName-applicant {
  color: #1d1e1f;
}

.applicant-same-row {
  margin-top: 1.25rem;
}

.input-applicant {
  margin-top: 5px !important;
  width: 100%;
}

.input-applicant-class {
  width: 100%;
}

.input-applicant-resume {
  display: none !important;
  width: 50%;
}

.required-input {
  color: red;
}

.button-applicant {
  display: flex;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  justify-content: flex-end;
  gap: 1rem;
  padding-top: 0.5rem;
}

.button__single {
  margin-top: 0.625rem;
  margin-left: 0.625rem;
}
.resume-container {
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.resume-name {
  margin-left: 10px;
  color: #1d1e1f;
  font-size: 14px;
  font-family: 'Open Sans';
  line-height: 24px;
}

.PrivateSwitchBase-root-9 {
  padding: 5px 5px !important;
  margin-left: 4px !important;
}

.applicant-same-row-flex {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

.add-another-section-text_application_form {
  color: #1d1e1f;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #ceece8;
  padding: 5px;
  border-radius: 4px;
}

.remove-button {
  margin-left: 5px;
  margin-top: 10px;
}

.remove-another-section-text {
  color: #1d1e1f;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: #f1d3b6;
  padding: 3px 15px;
  font-size: large;
  font-weight: 600;
  border-radius: 4px;
}

.add-remove-button-container_application_form {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.input-date-division {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-left: 1rem;
  padding-top: 1rem;
}

.flex_center {
  display: flex;
  align-items: center;
}

.input-date_application {
  padding-right: 10px;
  color: #1d1e1f;
}

.input-end-date {
  color: #1d1e1f;
  padding-left: 20px;
  padding-right: 10px;
}

.loader {
  margin-right: 60px;
}

.applicant-same-row-border, .applicant-same-row-border-margin-less {
  margin-top: 0.25rem;
  padding: 10px;
  border: 1px dashed rgba(0, 0, 0, 0.281);
}

.applicant-same-row-border{
  margin-top: 1.25rem;
}
.wrapper-class {
  margin-top: 5px;
  outline: none;
  border: 1px solid #b8b8b8 !important;
  border-radius: 4px;
}

.wrapper-class:focus {
  border: 2px solid #52bbab !important;
  border-radius: 4px;
}

.toolbar-class {
  border: 0px solid #b8b8b8 !important;
}

.editor-class {
  color: #1d1e1f;
  height: auto;
  cursor: text;
  min-height: 150px;
  border-top: 1px solid #b8b8b8;
  padding: 5px;
}

.select-options {
  height: 40px;
  width: 100px;
}

.select-options-full-width {
  min-width: 125px;
  height: 40px;
}

.select-company {
  width: 100%;
}

.select-options-second {
  width: 100px;
  margin-left: 20px !important;
}

.month-date {
  margin-bottom: 0px !important;
  width: 170px;
  height: 40px;
  border: 1px solid #b8b8b8;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 0px 5px;
}

.month-date.invalid {
  cursor: pointer;
  outline: none;
  border: 1px solid red;
  border-radius: 4px;
}

.month-date:hover {
  cursor: pointer;
}

.month-date:focus {
  cursor: pointer;
  outline: none;
  border: 2px solid #52bbab;
  border-radius: 4px;
}

.checkbox-text {
  padding-left: 10px;
  padding-bottom: 0.25rem;
  color: #1d1e1f;
}

.asterisk-field {
  display: flex;
  flex-wrap: wrap;
  column-gap: 5px;
  row-gap: 5px;
}

.asterisk {
  color: red;
}

.flex-class {
  display: flex;
  margin-top: 5px;
  column-gap: 5px;
  width: 100%;
}

.flex-class__institution {
  display: flex;
  width: 50%;
  column-gap: 5px;
}

.flex-class__select_field {
  width: 100%;
}

.flex-class__select {
  display: flex;
  column-gap: 5px;
  width: 25%;
}

.applicant-clear-icon {
  cursor: pointer;
}

.applicantFormDisabled {
  pointer-events: none;
}

@media screen and (max-width: 960px) {
  .page-applicant {
    padding: 0rem;
  }
  .applicant-info {
    padding: 1rem;
  }
  .input-date-division {
    display: initial;
  }
}
