.multiple-values-input-field {
  min-width: 300px !important;
}

.MuiAutocomplete-groupLabel {
  color: gray;
}

.global-search-icon {
  padding: 7px;
}
