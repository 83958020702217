.main-container-toolbar {
  display: flex;
  align-items: center;
  background-color: transparent;
  flex-wrap: nowrap;
  flex: auto;
  margin-left: 3rem;
}
.main-container-toolbar.sidebar-open {
  margin-left: 0rem;
}
.toolbar-item-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex: auto;
  flex-wrap: nowrap;
  column-gap: 1rem;
  padding-left: 1rem;
}
.user-circle {
  border: none;
  background-color: #1d1e1f;
  cursor: pointer;
  border-radius: 50%;
  height: 30px;
  min-width: 30px;
  color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
}
.search {
  max-width: 350px;
  flex: auto;
}

.head-text__heading {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex: auto;
  gap: 1rem;
}

@media screen and (max-width: 960px) {
  .search {
    margin: 0;
    width: auto;
    flex: none;
  }
  .main-container-toolbar {
    padding-right: 1rem;
  }
}
