.requiredInput {
  color: red;
}

.inputText {
  width: 100%;
  margin-top: 5px;
  height: 40px;
}

.label {
  color: #1d1e1f;
}

.loader {
  margin: 0 auto;
}
