* {
  box-sizing: border-box;
}

.MuiAutocomplete-input {
  padding: 0.656rem !important;
}

.MuiAutocomplete-inputRoot {
  padding: 0px !important;
}

.MuiTextField-root label {
  top: -0.5rem;
}

.MuiTextField-root input {
  padding-top: 0.656rem;
  padding-bottom: 0.656rem;
}

.MuiTextField-root label {
  top: -0.5rem;
}

.MuiTextField-root label[data-shrink='true'] {
  top: 0rem;
}

.hiring_portal_app {
  display: flex;
}

.hiring-motion_link {
  color: blue;
  text-decoration: none;
}

.hiring-motion_link:hover {
  text-decoration: underline;
}

.hiring-motion_link:active {
  color: red;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.head-text {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #1d1e1f;
}

.hiring-motion_checkbox.Mui-disabled {
  color: rgb(82, 187, 171, 0.5) !important;
}

.no-wrap {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hiring-motion_div_scroll__shadow {
  background: linear-gradient(white 30%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), white 70%) 0 100%,
    radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)),
    radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0)) 0 100%;
  background-color: rgb(255, 255, 255);
  background-repeat: no-repeat;
  background-size: 100% 40px, 100% 40px, 100% 14px, 100% 14px;
  background-attachment: local, local, scroll, scroll;
}

.hiring-motion_div_horizontal_scroll__shadow {
  background-image: linear-gradient(to right, white, white), linear-gradient(to right, white, white),
    linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0)),
    linear-gradient(to left, rgba(0, 0, 0, 0.1), rgba(255, 255, 255, 0));
  background-position: left center, right center, left center, right center;
  background-repeat: no-repeat;
  background-color: white;
  background-size: 20px 100%, 20px 100%, 20px 100%, 20px 100%;
  background-attachment: local, local, scroll, scroll;
}

p {
  margin: 0;
}

ul {
  list-style-type: disc;
}

@media screen and (max-width: 960px) {
  .head-text {
    font-size: 1rem;
  }
}
